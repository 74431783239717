<template>
  <div class="liexian">
    <h3><PopularScience :text="'裂隙灯'" :gjz="'lixideng'"></PopularScience></h3>
    <ul class="medd-content pad-10">
      <li></li>
      <li><PopularScience :text="'眼睑'" :gjz="'waiyan'"></PopularScience></li>
      <li>睑板腺</li>
      <li><PopularScience :text="'结膜'" :gjz="'jiemo'"></PopularScience></li>
      <li><PopularScience :text="'角膜'" :gjz="'jiaomo'"></PopularScience></li>
      <li><PopularScience :text="'前房'" :gjz="'qianfang'"></PopularScience></li>
    </ul>
    <ul class="medd-content">
      <li>OD</li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_SurfaceEyeOD"
          @change="bh($event, 'S_OP_EC_SurfaceEyeOD', wy[0].value, 'S_OP_EC_SurfaceEyeOD')"
          @focus="xzIdx = 1"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in wy"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_meibomianOD"
          @change="bh($event, 'S_OP_EC_meibomianOD', jbx[0].value, 'S_OP_EC_meibomianOD')"
          @focus="xzIdx=15"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in jbx"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>

      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_NippleOD"
          @change="bh($event, 'S_OP_EC_NippleOD', jm[0].value, 'S_OP_EC_NippleOD')"
          @focus="xzIdx=3"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in jm"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_CornealOD"
          @change="bh($event, 'S_OP_EC_CornealOD', jiaomo[0].value, 'S_OP_EC_CornealOD')"
          @focus="xzIdx=4"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in jiaomo"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_AnteroomOD"
          @change="bh($event, 'S_OP_EC_AnteroomOD', qf[0].value, 'S_OP_EC_AnteroomOD')"
          @focus="xzIdx=5"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in qf"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>

    </ul>
    <ul class="medd-content">
      <li>OS</li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_SurfaceEyeOS"
          @change="bh($event, 'S_OP_EC_SurfaceEyeOS', wy[0].value, 'wyOS')"
          @focus="xzIdx = 6"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in wy"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_meibomianOS"
          @change="bh($event, 'S_OP_EC_meibomianOS', jbx[0].value, 'S_OP_EC_meibomianOS')"
          @focus="xzIdx=20"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in jbx"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>

      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_NippleOS"
          @change="bh($event, 'S_OP_EC_NippleOS', jm[0].value, 'S_OP_EC_NippleOS')"
          @focus="xzIdx=8"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in jm"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_CornealOS"
          @change="bh($event, 'S_OP_EC_CornealOS', jiaomo[0].value, 'S_OP_EC_CornealOS')"
          @focus="xzIdx=9"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in jiaomo"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_AnteroomOS"
          @change="bh($event, 'S_OP_EC_AnteroomOS', qf[0].value, 'S_OP_EC_AnteroomOS')"
          @focus="xzIdx=10"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in qf"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
    </ul>

    <ul class="medd-content pad-10">
      <li></li>
      <li>泪器</li>
      <li><PopularScience :text="'虹膜'" :gjz="'hongmo'"></PopularScience></li>
      <li><PopularScience :text="'瞳孔'" :gjz="'tongkong'"></PopularScience></li>
      <li><PopularScience :text="'晶状体'" :gjz="'jingzhuangti'"></PopularScience></li>
      <!--      <li>泪河</li>-->
      <li>玻璃体</li>
    </ul>
    <ul class="medd-content">
      <li>OD</li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_CongestiveOD"
          @change="bh($event, 'S_OP_EC_CongestiveOD', lq[0].value, 'S_OP_EC_CongestiveOD')"
          @focus="xzIdx=101"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in lq"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_IrisOD"
          @change="bh($event, 'S_OP_EC_IrisOD', hm[0].value, 'S_OP_EC_IrisOD')"
          @focus="xzIdx = 11"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in hm"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_PupilOD"
          @change="bh($event, 'S_OP_EC_PupilOD', tk[0].value, 'S_OP_EC_PupilOD')"
          @focus="xzIdx=12"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in tk"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_CrystalOD"
          @change="bh($event, 'S_OP_EC_CrystalOD', jzt[0].value, 'S_OP_EC_CrystalOD')"
          @focus="xzIdx=13"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in jzt"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <!--      <li><el-select-->
      <!--          class="srr"-->
      <!--          v-model="lhOD"-->
      <!--          @change="bh"-->
      <!--          @focus="xzIdx = 14"-->
      <!--          filterable-->
      <!--          allow-create-->
      <!--          multiple-->
      <!--          collapse-tags-->
      <!--          default-first-option-->
      <!--          placeholder="请选择或输入">-->
      <!--        <el-option-->
      <!--            v-for="item in lh"-->
      <!--            :key="item.value"-->
      <!--            :label="item.label"-->
      <!--            :value="item.value">-->
      <!--        </el-option>-->
      <!--      </el-select></li>-->
      <li><el-select
          class="srr"
          v-model="S_OP_EC_lacrimalOD"
          @change="bh($event, 'S_OP_EC_lacrimalOD', blt[0].value, 'S_OP_EC_lacrimalOD')"
          @focus="xzIdx=103"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in blt"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
    </ul>
    <ul class="medd-content">
      <li>OS</li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_CongestiveOS"
          @change="bh($event, 'S_OP_EC_CongestiveOS', lq[0].value, 'S_OP_EC_CongestiveOS')"
          @focus="xzIdx=102"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in lq"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_IrisOS"
          @change="bh($event, 'S_OP_EC_IrisOS', hm[0].value, 'S_OP_EC_IrisOS')"
          @focus="xzIdx=16"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in hm"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_PupilOS"
          @change="bh($event, 'S_OP_EC_PupilOS', tk[0].value, 'S_OP_EC_PupilOS')"
          @focus="xzIdx=17"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in tk"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_CrystalOS"
          @change="bh($event, 'S_OP_EC_CrystalOS', jzt[0].value, 'S_OP_EC_CrystalOS')"
          @focus="xzIdx=18"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in jzt"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
      <!--      <li><el-select-->
      <!--          class="srr"-->
      <!--          v-model="lhOS"-->
      <!--          @change="bh"-->
      <!--          @focus="xzIdx=19"-->
      <!--          filterable-->
      <!--          allow-create-->
      <!--          multiple-->
      <!--          collapse-tags-->
      <!--          default-first-option-->
      <!--          placeholder="请选择或输入">-->
      <!--        <el-option-->
      <!--            v-for="item in lh"-->
      <!--            :key="item.value"-->
      <!--            :label="item.label"-->
      <!--            :value="item.value">-->
      <!--        </el-option>-->
      <!--      </el-select></li>-->
      <li><el-select
          class="srr"
          v-model="postData.S_OP_EC_lacrimalOS"
          @change="bh($event, 'S_OP_EC_lacrimalOS', blt[0].value, 'S_OP_EC_lacrimalOS')"
          @focus="xzIdx=104"
          filterable
          allow-create
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择或输入">
        <el-option
            v-for="item in blt"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select></li>
    </ul>


    <div style="padding: 20px 0;text-align: left;">
      <UPfile :type="'SL'"></UPfile>
      <el-upload
          v-if="0"
          action="#"
          :multiple="true"
          list-type="picture-card"
          accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"
          :file-list="fileLists"
          ref="uptqs"
          :on-change="sc"
          :before-upload="scsb"
          :auto-upload="false">
        <!--       <i slot="default" class="el-icon-plus"></i>-->
        <div>上传图片</div>
        <div slot="file" slot-scope="{file}">
          <img
              class="el-upload-list__item-thumbnail"
              :src="file.url" alt=""
          >
          <span class="el-upload-list__item-actions">
          <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </div>
      </el-upload>
    </div>

    <div class="bottom">
      <p>备注</p>
      <el-input
          class="bzs"
          type="textarea"
          placeholder="请输入内容"
          v-model="postData.S_OP_EC_Remark2"
          :autosize="{ minRows: 4, maxRows: 4 }"
          resize="none"
          show-word-limit
      >
      </el-input>
    </div>
  </div>
</template>

<script>
import pf from "@/publicFn/baseFn.js"
export default {
  name: "liexian",
  data() {
    return {
      bz: '',
      xzIdx: -1,
      bhIdx: -1,
      postData: {
        S_OP_EC_SurfaceEyeOD: ['无倒睫，眼睑闭合完'],
        S_OP_EC_SurfaceEyeOS: ['无倒睫，眼睑闭合完'],
        S_OP_EC_NippleOD: ['无充血、水肿'],
        S_OP_EC_NippleOS: ['无充血、水肿'],
        S_OP_EC_CornealOD: ['透明'],
        S_OP_EC_CornealOS: ['透明'],
        S_OP_EC_AnteroomOD: ['清、深'],
        S_OP_EC_AnteroomOS: ['清、深'],
        S_OP_EC_CongestiveOD: ['泪点位正'],
        S_OP_EC_CongestiveOS: ['泪点位正'],
        S_OP_EC_IrisOD: ['纹理清'],
        S_OP_EC_IrisOS: ['纹理清'],
        S_OP_EC_PupilOD: ['PERRL'],
        S_OP_EC_PupilOS: ['PERRL'],
        S_OP_EC_CrystalOD:  ['透明'],
        S_OP_EC_CrystalOS:  ['透明'],
        S_OP_EC_lacrimalOD: ['无混浊'],
        S_OP_EC_lacrimalOS: ['无混浊'],
        S_OP_EC_Remark2: '',
        S_OP_EC_meibomianOD: ['未见异常'],
        S_OP_EC_meibomianOS: ['未见异常']
      },
      idx: 0,
      fileLists: [],
      disabled: false,
      dialogVisible: false,
      dialogImageUrl: '',
      wy: [
        {
          value: '无倒睫，眼睑闭合完',
          label: '无倒睫，眼睑闭合完'
        },
        {
          value: '红肿',
          label: '红肿'
        },
        {
          value: '睑外翻',
          label: '睑外翻'
        },
        {
          value: '睑内翻',
          label: '睑内翻'
        },
        {
          value: '上脸下垂',
          label: '上脸下垂'
        },
        {
          value: '闭合不全',
          label: '闭合不全'
        },
        {
          value: '倒睫',
          label: '倒睫'
        },
        {
          value: '内眦赘皮',
          label: '内眦赘皮'
        },
        {
          value: '眼球突出',
          label: '眼球突出'
        },
        {
          value: '睑缘炎',
          label: '睑缘炎'
        },
      ],
      S_OP_EC_SurfaceEyeOD: ['无倒睫，眼睑闭合完'],
      S_OP_EC_SurfaceEyeOS: ['无倒睫，眼睑闭合完'],
      /*jmcx:[
        {
          value: '无充血、水肿',
          label: '无充血、水肿'
        },
        {
          value: '球结膜充血+',
          label: '球结膜充血+'
        },
        {
          value: '球结膜充血++',
          label: '球结膜充血++'
        },
        {
          value: '球结膜充血+++',
          label: '球结膜充血+++'
        },
        {
          value: '睑结膜充血+',
          label: '睑结膜充血+'
        },
        {
          value: '睑结膜充血++',
          label: '睑结膜充血++'
        },
        {
          value: '睑结膜充血+++',
          label: '睑结膜充血+++'
        },
        {
          value: '混合充血+',
          label: '混合充血+'
        },
        {
          value: '混合充血++',
          label: '混合充血++'
        },
        {
          value: '混合充血+++',
          label: '混合充血+++'
        }
      ],
      jmcxOD:['无充血、水肿'],
      jmcxOS:['无充血、水肿'],*/

      jm: [
        {
          value: '无充血、水肿',
          label: '无充血、水肿'
        },
        {
          value: '水肿',
          label: '水肿'
        },
        {
          value: '乳头、滤泡增生(+)',
          label: '乳头、滤泡增生(+)'
        },
        {
          value: '乳头、滤泡增生(++)',
          label: '乳头、滤泡增生(++)'
        },
        {
          value: '乳头、滤泡增生(+++)',
          label: '乳头、滤泡增生(+++)'
        },
        {
          value: '结石',
          label: '结石'
        },
        {
          value: '睑板瘢痕',
          label: '睑板瘢痕'
        },
        {
          value: '睑裂斑',
          label: '睑裂斑'
        },
        {
          value: '翼状胬肉',
          label: '翼状胬肉'
        },
        {
          value: '球结膜干燥',
          label: '球结膜干燥'
        },
        {
          value: '睑结膜充血',
          label: '睑结膜充血'
        },
        {
          value: '球结膜充血',
          label: '球结膜充血'
        }
      ],
      S_OP_EC_NippleOD: ['无充血、水肿'],
      S_OP_EC_NippleOS: ['无充血、水肿'],

      jiaomo: [
        {
          value: '透明',
          label: '透明'
        },
        {
          value: '上皮点状缺损',
          label: '上皮点状缺损'
        },
        {
          value: '上皮大面积缺损',
          label: '上皮大面积缺损'
        },
        {
          value: '云翳',
          label: '云翳'
        },
        {
          value: '白斑',
          label: '白斑'
        },
        {
          value: '浸润',
          label: '浸润'
        },
        {
          value: '溃疡',
          label: '溃疡'
        },
        {
          value: '新生血管',
          label: '新生血管'
        },
        {
          value: '异物',
          label: '异物'
        },
        {
          value: '水肿',
          label: '水肿'
        },
        {
          value: '中央减薄',
          label: '中央减薄'
        },
        {
          value: '老年性退变',
          label: '老年性退变'
        },
        {
          value: '放射状切开术后',
          label: '放射状切开术后'
        },
        {
          value: '异常物质沉着',
          label: '异常物质沉着'
        },
        {
          value: '先天性小角膜',
          label: '先天性小角膜'
        },
        {
          value: 'Vogt',
          label: 'Vogt'
        }
      ],
      S_OP_EC_CornealOD: ['透明'],
      S_OP_EC_CornealOS: ['透明'],

      qf: [
        {
          value: '清、深',
          label: '清、深'
        },
        {
          value: '周边前房≤1/3CT',
          label: '周边前房≤1/3CT'
        },
        {
          value: '周房水闪辉',
          label: '周房水闪辉'
        },
        {
          value: '房水混浊',
          label: '房水混浊'
        }
      ],
      S_OP_EC_AnteroomOD: ['清、深'],
      S_OP_EC_AnteroomOS: ['清、深'],

      lq: [
          {value: '泪点位正', label: '泪点位正'},
          {value: '', label: ''}
      ],
      S_OP_EC_CongestiveOD: ['泪点位正'],
      S_OP_EC_CongestiveOS: ['泪点位正'],

      hm: [
        {
          value: '纹理清',
          label: '纹理清'
        },
        {
          value: '色素缺失',
          label: '色素缺失'
        },
        {
          value: '缺损',
          label: '缺损'
        },
        {
          value: '萎缩斑',
          label: '萎缩斑'
        },
        {
          value: '膨隆',
          label: '膨隆'
        },
        {
          value: '新生血管',
          label: '新生血管'
        }
      ],
      S_OP_EC_IrisOD: ['纹理清'],
      S_OP_EC_IrisOS: ['纹理清'],

      tk: [
        {
          value: 'PERRL',
          label: 'PERRL'
        },
        {
          value: '散大',
          label: '散大'
        },
        {
          value: '针尖样',
          label: '针尖样'
        },
        {
          value: '变形',
          label: '变形'
        },
        {
          value: '直接对光反射消失',
          label: '直接对光反射消失'
        },
        {
          value: '间接对光反射消失',
          label: '间接对光反射消失'
        },
        {
          value: 'MG(+)',
          label: 'MG(+)'
        }
      ],
      S_OP_EC_PupilOD: ['PERRL'],
      S_OP_EC_PupilOS: ['PERRL'],

      jzt: [
        {
          value: '透明',
          label: '透明'
        },
        {
          value: '无晶体眼',
          label: '无晶体眼'
        },
        {
          value: '人工晶体眼',
          label: '人工晶体眼'
        },
        {
          value: '异位或脱落',
          label: '异位或脱落'
        },
        {
          value: '混浊',
          label: '混浊'
        }
      ],
      S_OP_EC_CrystalOD: ['透明'],
      S_OP_EC_CrystalOS: ['透明'],

      /*lh: [
        {
          value: '充盈',
          label: '充盈'
        },
        {
          value: '不充盈',
          label: '不充盈'
        }
      ],
      lhOD: [],
      lhOS: [],

      jbxOD: ['未见异常'],
      jbxOS: ['未见异常'],*/

      jbx: [
        {
          value: '未见异常',
          label: '未见异常'
        },
        {
          value: '开口堵塞',
          label: '开口堵塞'
        },
        {
          value: '分泌物',
          label: '分泌物'
        },
        {
          value: '',
          label: ''
        }
      ],

      blt: [
        {
          value: '无混浊',
          label: '无混浊'
        },
        {
          value: '',
          label: ''
        }
      ],
      S_OP_EC_lacrimalOD: ['无混浊'],
      S_OP_EC_lacrimalOS: ['无混浊']
    }
  },
  watch: {
    '$store.state.eyeTest.lxdata': {
      deep: true,
      handler(n, o) {
        for (let key in this.postData) {
          if (key === 'S_OP_EC_Remark2') {
            this.postData[key] = n.S_OP_Json[key]
          }else {
            this.postData[key] = n.S_OP_Json[key]?n.S_OP_Json[key].split(','):this.postData[key]
          }
        }
      }
    }
  },
  created() {

    if (!this.$store.state.users.CsUser) {
      return this.$alert('用户没有登录！请登录', '提示')
          .then(res => {
            this.$router.push({path: '/home/acceptsHome'})
          })
    }

    if (!this.$store.state.physicianVisits.xzConsulting) {
      return this.$alert('请选择或者新建咨询', '提示')
          .then(res => {
            this.$router.push({path: '/home/acceptsHome'})
          })
    }


    this._api.publicApi.getImgList('SL')
        .then(res => {
          let arr = []
          if (res.GetListResult && res.GetListResult.length > 0) {
            for (let i = 0; i < res.GetListResult.length; i++) {
              arr.push({
                name: res.GetListResult[i].UFId,
                url: res.GetListResult[i].FullURL,
              })
            }
            this.fileLists = arr
          }
        })

  },
  methods: {
    bh(e, key, opval, arr) {
      // console.log(e, key, opval)
      // console.log(this[arr], this[arr].indexOf(opval))
      // let idx = this[arr].indexOf(opval)
      // if (idx > -1 && this[arr].length > 1) {
      //   if (idx == this[arr].length -1) {
      //     this[arr] = [opval]
      //   }else if (idx == 0) {
      //     this[arr].shift()
      //   }
      // }
      if (e.length > 1) {
        if (e[e.length -1] == opval) {
          this.postData[key] = [opval]
        }else {
          if (e[0] == opval) {
            this.postData[key].shift()
          }
        }
      }
    },


    clStr(str) {
      if (!str) return  ''
      return str.split(',')
    },

    sc(file, f) {
      let _this = this
      let reader = new FileReader(), imgBase64 = '';
      reader.readAsDataURL(file.raw)
      reader.onload = function (e) {
        _this._http.post(pf.getUrl('UploadFile_Upload'), {
          "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
          },
          o: {
            FileBase64: e.currentTarget.result.split(',')[1],
            ExtensionName: ".png",
            UFSaveType: 'OSSPrivate',
            UFReObject: "Choose",
            UFType: 'SL',
            UFReId: _this.$store.state.physicianVisits.xzConsulting
          }
        })
            .then(res => {
              // console.log(res)
              if (res.Id) {
                file.name = res.Id
              }else {
                let upwj = this.$refs.uptqs.uploadFiles
                upwj.splice(upwj.length-1, 1)
              }
            })
      }
    },

    handleRemove(file) {
      this._api.publicApi.deleImg(file.name)
          .then(res => {
            if (res.DeleteByIdReturn) {
              let upwj = this.$refs.uptqs.uploadFiles
              for (let i = 0; i < upwj.length; i++) {
                if (upwj[i]['url'] == file.url) {
                  upwj.splice(i, 1)
                }
              }
            }
          })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      //console.log(file);
    },
    scsb(err) {
      console.log(err)
    },
    save() {
      this._api.eyeTest.saveLxd(JSON.parse(JSON.stringify(this.postData)), '裂隙灯检查')
    }
  },
  components: {
    UPfile: () => import('@/components/CommonComponents/upFile')
  }
}
</script>

<style scoped lang="scss">
.liexian {
  width: 90%;
  margin: 0 auto;
}
h3 {
  padding: 0.3rem 0;
}
.medd-content {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
  li {
    width: 12vw;
    //height: 32px;
    //line-height: 32px;
    margin-right: 2%;
  }
  li:nth-child(1) {
    width: 3vw;
    text-align: left;
  }
}
.pad-10{padding-top: 15px;}
.bottom {
  //width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: start;
  margin-top: 20px;
  .bzs {
    width: 95%;
  }
  p {width: 5%;text-align: left}
}
</style>
